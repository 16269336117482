import BG_1 from "../assets/service-bg/bg_1.png";
import BG_2 from "../assets/service-bg/bg_2.png";
import BG_3 from "../assets/service-bg/bg_3.png";
import BG_4 from "../assets/service-bg/bg_4.png";
import BG_5 from "../assets/service-bg/bg_5.png";
import BG_6 from "../assets/service-bg/bg_6.png";
import BG_7 from "../assets/service-bg/bg_7.png";
import BG_8 from "../assets/service-bg/bg_8.png";
import BG_9 from "../assets/service-bg/bg_9.png";
import BG_10 from "../assets/service-bg/bg_10.png";
import BG_11 from "../assets/service-bg/bg_11.png";
import BG_12 from "../assets/service-bg/bg_12.png";
import BG_13 from "../assets/service-bg/bg_13.png";
import BG_14 from "../assets/service-bg/bg_14.png";
import BG_15 from "../assets/service-bg/bg_15.png";
import BG_17 from "../assets/service-bg/bg_17.png";
import BG_18 from "../assets/service-bg/bg_18.png";
import BG_19 from "../assets/service-bg/bg_19.png";
import BG_20 from "../assets/service-bg/bg_20.png";
import BG_21 from "../assets/service-bg/bg_21.png";
import BG_22 from "../assets/service-bg/bg_22.png";
import BG_23 from "../assets/service-bg/bg_23.png";
import BG_24 from "../assets/service-bg/bg_24.png";
import BG_25 from "../assets/service-bg/bg_25.png";
import BG_26 from "../assets/service-bg/bg_26.png";
import BG_27 from "../assets/service-bg/bg_27.png";
import BG_28 from "../assets/service-bg/bg_28.png";
import BG_29 from "../assets/service-bg/bg_29.png";
import BG_30 from "../assets/service-bg/bg_30.png";
import BG_31 from "../assets/service-bg/bg_31.png";

export const allServices = {

    manicure: [
        {
            bg: BG_1,
            value: 'Classic Manicure',
            startPrice: 3000,
            endPrice: null,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },
        {
            bg: BG_2,
            value: 'Manicure + Simple Lac',
            startPrice: 5000,
            endPrice: null,
            hour: 1,
            minute: null,
            timeToMinute: 60
        },
        {
            bg: BG_30,
            value: 'Gel Lac + Manicure (Luxio)',
            startPrice: 9000,
            endPrice: null,
            hour: 1,
            minute: 30,
            timeToMinute: 90
        },
        {
            bg: BG_3,
            value: 'Gel Lac + Manicure (Rock Nail)',
            startPrice: 6000,
            endPrice: null,
            hour: 1,
            minute: 30,
            timeToMinute: 90
        },
        {
            bg: BG_4,
            value: 'Gel Lac Removal',
            startPrice: 1000,
            endPrice: null,
            hour: null,
            minute: 15,
            timeToMinute: 15
        },
        {
            bg: BG_5,
            value: 'Nail Fixing',
            startPrice: 3000,
            endPrice: null,
            hour: null,
            minute: 20,
            timeToMinute: 20
        },
        {
            bg: BG_6,
            value: 'Japanese Manicure',
            startPrice: 7000,
            endPrice: null,
            hour: null,
            minute: 40,
            timeToMinute: 40
        },
        {
            bg: BG_7,
            value: 'Nail Extension',
            startPrice: 18000,
            hour: 2,
            minute: null,
            timeToMinute: 120
        },
        {
            bg: BG_8,
            value: 'Extension Correction',
            startPrice: 15000,
            endPrice: null,
            hour: 1,
            minute: 40,
            timeToMinute: 100
        },
        {
            bg: BG_9,
            value: 'Repair Of One Nail',
            startPrice: 500,
            endPrice: null,
            hour: null,
            minute: 15,
            timeToMinute: 15
        },
        {
            bg: BG_10,
            value: 'Padding For One Nail',
            startPrice: 1000,
            endPrice: null,
            hour: null,
            minute: 15,
            timeToMinute: 15
        },
        {
            bg: BG_11,
            value: 'Extension Removal',
            startPrice: 2000,
            endPrice: null,
            hour: null,
            minute: 25,
            timeToMinute: 25
        },

        {
            bg: BG_12,
            value: 'Hand Acupressure',
            startPrice: 3000,
            endPrice: null,
            hour: null,
            minute: 15,
            timeToMinute: 15
        },
        {
            bg: BG_13,
            value: 'Hands paraffin therapy',
            startPrice: 3500,
            endPrice: null,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },

    ],
    pedicure: [
        {
            bg: BG_14,
            value: 'Classic Pedicure',
            startPrice: 7000,
            endPrice: null,
            hour: null,
            minute: 40,
            timeToMinute: 40
        },
        {
            bg: BG_15,
            value: 'Pedicure + Simple Lac',
            startPrice: 9000,
            endPrice: null,
            hour: 1,
            minute: 10,
            timeToMinute: 70
        },
        {
            bg: BG_31,
            value: 'Pedicure + Gel Lac',
            startPrice: 10000,
            endPrice: null,
            hour: 1,
            minute: 10,
            timeToMinute: 70
        },
        {
            bg: BG_17,
            value: 'Feet paraffin therapy',
            startPrice: 5000,
            endPrice: null,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },
        {
            bg: BG_18,
            value: 'Heel Treatment',
            startPrice: 2000,
            endPrice: null,
            hour: null,
            minute: 20,
            timeToMinute: 20
        },
        {
            bg: BG_19,
            value: 'Feet Acupressure',
            startPrice: 5000,
            endPrice: null,
            hour: null,
            minute: 15,
            timeToMinute: 15
        },
    ],
    faceSkinCare: [
        {
            bg: BG_20,
            value: "Men's Manicure",
            startPrice: 5000,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },
        {
            bg: BG_21,
            value: "Men's Pedicure",
            startPrice: 11000,
            endPrice: null,
            hour: 1,
            minute: null,
            timeToMinute: 60
        },
        {
            bg: BG_22,
            value: "Men's Pedicure (Without Heel Treatment)",
            startPrice: 9000,
            endPrice: null,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },
        {
            bg: BG_23,
            value: "Men’s hands paraffin therapy",
            startPrice: 6000,
            endPrice: null,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },
        {
            bg: BG_24,
            value: "Men’s Feet paraffin therapy",
            startPrice: 8000,
            endPrice: null,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },
    ],
    design: [
        {
            bg: BG_25,
            value: "Design Of Medium Complexity",
            startPrice: 500,
            endPrice: 3000,
            hour: null,
            minute: 10,
            timeToMinute: 30
        },

        {
            bg: BG_26,
            value: "Stamping",
            startPrice: 3000,
            endPrice: null,
            hour: null,
            minute: 15,
            timeToMinute: 15
        },
        {
            bg: BG_27,
            value: "French",
            startPrice: 3000,
            endPrice: null,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },
        {
            bg: BG_28,
            value: "Ombre",
            startPrice: 3000,
            endPrice: null,
            hour: null,
            minute: 30,
            timeToMinute: 30
        },
        {
            bg: BG_29,
            value: "The Rub",
            startPrice: 3000,
            endPrice: null,
            hour: null,
            minute: 15,
            timeToMinute: 15
        },
    ]
}

export const allMasters = [
    {
        value: "Irina Kostanyan",
        disabled: false
    },
    {
        value: "Marianna Badalyan",
        disabled: false
    },
]