export const translationEN = {
    "About Us": "About Us",
    "Our Services": "Our Services",
    "Contact Us": "Contact Us",
    "Book Now": 'Book Now',
    "Discover the ultimate in nail care luxury at CHIC ∙ CHOC Nail Salon. Treat yourself to expert manicures and pedicures in our serene and welcoming atmosphere. Book your appointment today for a rejuvenating experience you won't forget!": " Discover the ultimate in nail care luxury at CHIC ∙ CHOC Nail Salon. Treat yourself to expert manicures and pedicures in our serene and welcoming atmosphere. Book your appointment today for a rejuvenating experience you won't forget!",
    "Step into a place of Beauty and Elegance, Where Your Nails Become a Canvas of Creativity.": "Step into a place of Beauty and Elegance, Where Your Nails Become a Canvas of Creativity.",
    "Vardananc 16, Yerevan, Armenia": "Vardananc 16, Yerevan, Armenia",
    "Our goal is to provide a luxurious and relaxing experience with excellent nail care and artistry, exceeding client expectations with a range of services including manicures, pedicures and creative nail designs.": "Our goal is to provide a luxurious and relaxing experience with excellent nail care and artistry, exceeding client expectations with a range of services including manicures, pedicures and creative nail designs.",
    "Dare to dream differently with us!": "Dare to dream differently with us!",
    "Got a quirky wish? We've got you covered! Let us sprinkle some magic and make it real, reflecting your awesome personality. And hey, while we're at it, let's pamper those nails with some luxe treatment too!": "Got a quirky wish? We've got you covered! Let us sprinkle some magic and make it real, reflecting your awesome personality. And hey, while we're at it, let's pamper those nails with some luxe treatment too!",
    "Manicure": "Manicure",
    "Pedicure": "Pedicure",
    "Get ready to witness perfection in action! Our awesome team is all about making your nails look and feel amazing. Whether you're after a fancy manicure or just chilling with a pedicure, we promise a super comfy vibe every step of the way.": "Get ready to witness perfection in action! Our awesome team is all about making your nails look and feel amazing. Whether you're after a fancy manicure or just chilling with a pedicure, we promise a super comfy vibe every step of the way.",
    "Go to Services": "Go to Services",
    "KIND WORDS FROM OUR CUSTOMERS": "KIND WORDS FROM OUR CUSTOMERS",
    "Look no further! We've got everything you've been searching for: top-notch service, expert pros, and the perfect chill vibe for maximum comfort. Welcome to your new happy place!": "Look no further! We've got everything you've been searching for: top-notch service, expert pros, and the perfect chill vibe for maximum comfort. Welcome to your new happy place!",
    "our story": "our story",
    "Okay, so picture this: Marieta Vardanyan, or Marik as her crew calls her, is this graphic designer, yeah? But get this – out of the blue, she's like, 'You know what Armenia needs? A killer nail salon!' So, she rolls up her sleeves, leaves the design board behind, and dives headfirst into the nail game. She's all about top-notch service, primo professionals, and making dreams come true. And that's how the magic began!": "Okay, so picture this: Marieta Vardanyan, or Marik as her crew calls her, is this graphic designer, yeah? But get this – out of the blue, she's like, 'You know what Armenia needs? A killer nail salon!' So, she rolls up her sleeves, leaves the design board behind, and dives headfirst into the nail game. She's all about top-notch service, primo professionals, and making dreams come true. And that's how the magic began!",
    "Creating an ideal environment": "Creating an ideal environment",
    "our team": "our team",
    "At our salon, our team is like a family, with each member bringing their unique skills to make your experience exceptional. We have a diverse group of specialists who are dedicated to making you look and feel your best. Our nail technicians are the artists behind those intricate nail designs you love. From classic manicures to elaborate nail art, they have the creativity and expertise to bring your vision to life. With years of experience and ongoing training, they stay updated on the latest trends and techniques, ensuring that your nails are always on point. But our team isn't just about technical skills – it's also about genuine care and connection. Each member is dedicated to making you feel welcomed, valued, and comfortable during your visit. They take the time to listen to your needs, answer your questions, and ensure that every aspect of your experience exceeds your expectations.": "At our salon, our team is like a family, with each member bringing their unique skills to make your experience exceptional. We have a diverse group of specialists who are dedicated to making you look and feel your best. Our nail technicians are the artists behind those intricate nail designs you love. From classic manicures to elaborate nail art, they have the creativity and expertise to bring your vision to life. With years of experience and ongoing training, they stay updated on the latest trends and techniques, ensuring that your nails are always on point. But our team isn't just about technical skills – it's also about genuine care and connection. Each member is dedicated to making you feel welcomed, valued, and comfortable during your visit. They take the time to listen to your needs, answer your questions, and ensure that every aspect of your experience exceeds your expectations.",
    "Visit our calm retreat and leave behind the busy pace of daily life. Our skilled technicians will provide you with exceptional nail care, creating the perfect experience for you.": "Visit our calm retreat and leave behind the busy pace of daily life. Our skilled technicians will provide you with exceptional nail care, creating the perfect experience for you.",
    "Services": "Services",
    "face skin care": "face skin care",
    "AMD": "֏",
    "hr": "hr",
    "min": "min",
    "Classic Manicure": "Classic Manicure",
    "Gel Nail Polish": "Gel Nail Polish",
    "Japanese Manicure": "Japanese Manicure",
    "Nail Fixing": "Nail Fixing",
    "Nail Extension": "Nail Extension",
    "Extension Correction": "Extension Correction",
    "Classic Pedicure": "Classic Pedicure",
    "Gel Pedicure": "Gel Pedicure",
    "Daily Make-Up": "Daily Make-Up",
    "Wedding Make-Up": "Wedding Make-Up",
    "Eyebrow Shaping": "Eyebrow Shaping",
    "Lamination": "Lamination",
    "Waxing": "Waxing",
    "Simply fill in the necessary information to secure your appointment with us. From preferred service to date and time, your nail care needs are in good hands.": "Simply fill in the necessary information to secure your appointment with us. From preferred service to date and time, your nail care needs are in good hands.",
    "Book a Visit": "Book a Visit",
    "Name Surname": "Name Surname",
    "Choose Master": "Choose Master",
    "Irina Kostanyan": "Irina Kostanyan",
    "Marianna Badalyan": "Marianna Badalyan",
    "Phone Number": "Phone Number",
    "Select the Service Type": "Select the Service Type",
    "Book": "Book",
    "The service will cost": "The service will cost",
    "Time": "Time",
    "OOOPS! PAGE NOT FOUND": "OOOPS! PAGE NOT FOUND",
    "Return Homepage": "Return Homepage",
    "Gel Lac + Manicure (Luxio)": "Gel Lac + Manicure (Luxio)",
    "Gel Lac + Manicure (Rock Nail)": "Gel Lac + Manicure (Rock Nail)",
    "Manicure + Simple Lac": "Manicure + Simple Lac",
    "Extension Removal": "Extension Removal",
    "Gel Lac Removal": "Gel Lac Removal",
    "Repair Of One Nail": "Repair Of One Nail",
    "Padding For One Nail": "Padding For One Nail",
    "Hand Acupressure": "Hand Acupressure",
    "Hands paraffin therapy": "Hands paraffin therapy",
    "Feet paraffin therapy": "Feet paraffin therapy",
    "Pedicure + Simple Lac": "Pedicure + Simple Lac",
    "Pedicure + Gel Lac": "Pedicure + Gel Lac",
    "Heel Treatment": "Heel Treatment",
    "Men's Manicure/Pedicure": "Men's Manicure/Pedicure",
    "Men's Manicure": "Men's Manicure",
    "Men's Pedicure": "Men's Pedicure",
    "Men's Pedicure (Without Heel Treatment)": "Pedicure (Without Heel Treatment)",
    "Men’s hands paraffin therapy": "Men’s hands paraffin therapy",
    "Men’s Feet paraffin therapy": "Men’s Feet paraffin therapy",
    "Design": "Design",
    "Design Of Medium Complexity": "Design Of Medium Complexity",
    "Stamping": "Stamping",
    "French": "French",
    "Ombre": "Ombre",
    "The Rub" : "The Rub",
    "Feet Acupressure": "Feet Acupressure",
    "Registration Successfully Completed": "Registration Successfully Completed",
    "Fill in all fields": "Fill in all fields",
    "Feel free to reach out to us using any of the methods below. Our dedicated team is ready to assist you and ensure that your experience with us is nothing short of exceptional.": "Feel free to reach out to us using any of the methods below. Our dedicated team is ready to assist you and ensure that your experience with us is nothing short of exceptional.",
    "Monday - Sunday": "Monday - Sunday",
    "Contact  Us": "Contact Us",
    "Fill in all the fields for registration": "Fill in all the fields for registration",
    "If you want to receive a reminder about registration, click the Subscribe button.": "If you want to receive a reminder about registration, click the Subscribe button.",
    "Subscribe": "Subscribe"

}